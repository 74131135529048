.main {
  background-image: url(../../../images/triangles.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f0eff5;
  color: white;
  height: auto;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
}
.card {
  display: block;
  background-color: #f4f7f8;
  color: black;

  min-height: 650px;
  width: 42vh;
  position: relative;
  left: 50%;
  top: 10%;
  border-radius: 20px;
  transform: translateX(-50%);
  padding-bottom: 10px;
  margin-top: 1rem;
}
.picture {
  box-shadow: 0.5px 0.5px 5px;
  margin-top: 60px;
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
}
.name {
  font-family: "SF Pro Display";
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.clipfy {
  margin-top: 20px;
  width: 30px;
}

.text {
  font-family: "SF Pro Display";
  font-size: 1rem;
  color: #000000;
  margin: 10px;
}
.desc {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 20px;
  height: 4vh;
  width: 60%;

  margin: 15px auto;
  background-color: #ffffff;
}
.links {
  margin: 20px 30px 10px 30px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-top: 40px;
  }
}
