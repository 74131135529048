.links {
  margin: 20px 40px 10px 40px;
}
.text {
  font-size: 0.8rem;
  margin: 10px;
  display: inline-block;
  width: 100%;
  align-items: center;
  text-align: center;
}
