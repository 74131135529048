.link {
  display: flex;
  cursor: pointer;
  color: white;
  font-size: 0.3rem;
  margin: 10px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: #314cfd;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;

  transition: filter 0.2s;
}
.link:hover {
  filter: brightness(0.9);
}
.link a {
  font-family: "SF Pro Display";
  font-size: 1.1rem;
  color: white;
  text-decoration: none;
}

.logo {
  margin: 0 15px;
  width: 35px;
  border-radius: 20px;
}
